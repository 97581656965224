import { Dialog, makeStyles } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'

const useStyles = makeStyles(theme => ({
  modal: {
    backgroundColor: '#FBFBF8',
    position: 'relative',
    padding: '20px'
  },
  teamName: {
    fontSize: '22px',
    padding: '8px 0',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.dark
    }
  }
}))
interface Offer {
  team: string
  content: string
}

interface Props {
  offer: Offer
}
export const OfferComponent = ({ offer }: Props) => {
  const styles = useStyles()
  const [open, setOpen] = useState(false)
  return (
    <div>
      <div
        className={styles.teamName}
        onClick={() => {
          setOpen(true)
        }}
      >
        {offer.team}
      </div>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        scroll="body"
      >
        <div
          dangerouslySetInnerHTML={{ __html: offer.content }}
          className={styles.modal}
        />
      </Dialog>
    </div>
  )
}
