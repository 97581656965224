import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { OfferComponent } from './OfferComponent'

const useStyles = makeStyles({
  offers: {
    marginLeft: '20px'
  }
})
interface Offer {
  team: string
  content: string
}

interface Props {
  offers: Array<Offer>
}
const OffersComponent = ({ offers }: Props) => {
  const styles = useStyles()
  return (
    <div className={styles.offers}>
      {offers.map(offer => (
        <OfferComponent offer={offer} />
      ))}
    </div>
  )
}

export default OffersComponent
